import React, { useState } from "react";
import useWindowSize from "../../redux/device/useWindowSize";
import Navbar from "../../components/Navbar/Navbar";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import {
  useAddtoCartMutation,
  useGetCartItemsByUserQuery,
  useGetCombobyIdQuery,
  useGetCombosByTypeQuery,
  useIsUserLoggedInMutation,
  useRemoveFromCartMutation,
} from "../../generated/graphql";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/device/reducers";
import "./combos.scss";
import axiosInstance from "../../graphql/axiosConfig";
import { Oatlayout, SizeChart } from "../../assets/constants";
import ImagePopup from "../../components/ImagePopUps/ImagePopUp";
import Popup from "../../ui-elements/popup/popup";
import Banner from "../../components/Banner/Banner";
import Timer from "../../components/Timer/Timer";



interface OtherCombos {
  id: string; // Assuming combos have an id field
  Name: string;
  Items: string;
  Image: string;
  mobileImage?: string | null;
  Description: string;
  Price: number;
  type: string;
}

const Combos: React.FC = () => {
  useWindowSize();
  const navigate = useNavigate();
  const { device } = useSelector((state: RootState) => state.windowSize);
  const { id = "" } = useParams<{ id: string }>();
  const [quantity, setQuantity] = useState<number>(1);

  const [isTypeCommon, setIsTypeCommon] = useState(false);
  const [selectedSize, setSelectedSize] = useState("N/A");
  const [isCustomizable, setIsCustomizable] = useState(false);
  const [customName, setCustomName] = useState<string | null>(null);

  const sizes = ["S", "M", "L", "XL"];

  const {
    data: combosData,
    loading: combosLoading,
    error: combosError,
  } = useGetCombobyIdQuery({
    variables: {
      Id: id,
    },
  });

  const combo = combosData?.getCombobyId;

  const { data, loading, error } = useGetCombosByTypeQuery({
    variables: {
      types: ["ticket", "common"]
    },
  });


  

  var OtherCombos: OtherCombos[] = data?.getCombosByType as OtherCombos[] ?? [];

  OtherCombos = OtherCombos.filter((OtherCombo) =>  OtherCombo.id !== id)

  

  const { data: cartItemsData } = useGetCartItemsByUserQuery();
  const [isUserLoggedInMutation, { data: loggedIn }] =
    useIsUserLoggedInMutation();
  const [addToCartMutation, { data: addData }] = useAddtoCartMutation();
  const [removeFromCartMutation] = useRemoveFromCartMutation();
  const [customNameError, setCustomNameError] = useState("");
  const [sizeError, setSizeError] = useState("");
  const [isUser, setIsUser] = useState(false);
  const [LoginPopUp, setLogInPopUp] = useState(false);

  async function IsUserLoggedIn() {
    const response = await axiosInstance.post("", {
      query: `
      mutation Mutation {
        IsUserLoggedIn
      }
      `,
    });

    if (!response.data.errors) setIsUser(true);
  }
  IsUserLoggedIn();

  const handlePositionChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setSelectedSize(event.target.value);
  };

  const handleCustomNameInput = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setCustomName(event.target.value);
  };

  const increaseQuantity = () => {
    setQuantity((prev) => prev + 1);
  };

  const decreaseQuantity = () => {
    if (quantity > 1) {
      setQuantity((prev) => prev - 1);
    }
  };
  

  const handleAddToCart = async () => {
    IsUserLoggedIn(); // Check if user is logged in

    if (isUser) {
      // Proceed only if the user is logged in
      if (
        (combo?.type == "common" || combo?.type == "merch") &&
        selectedSize == "N/A"
      ) {
        setSizeError("Please Select your Size");
        return;
      }
      if (combo?.type == "merch" && (customName == null || customName == "")) {
        setCustomNameError("Enter name");
        return;
      }
      try {
        await addToCartMutation({
          variables: {
            quantity: quantity,
            itemId: id,
            itemType: "Combo",
            name: combo?.Name,
            size: selectedSize, // Adjust as needed
            customizable: isCustomizable,
            customization: customName,
          },
        });
        setQuantity(1)
        setSizeError("")
        setCustomNameError("")
      } catch (error) {
        alert("Failed to add to cart");
      }
    } else {
      setLogInPopUp(true);
      setTimeout(() => {
        navigate("/auth");
      }, 2000);
    }
  };

  const handleRemoveFromCart = async () => {
    const { data: isLoggedInData } = await isUserLoggedInMutation(); // Check if user is logged in

    if (isLoggedInData?.IsUserLoggedIn) {
      // Proceed only if the user is logged in
      try {
        await removeFromCartMutation({
          variables: {
            quantity: quantity,
            itemId: id,
            itemType: "Combo",
            name: combo?.Name,
            size: "default", // Adjust as needed
          },
        });
      } catch (error) {
        alert("Failed to remove from cart");
      }
    } else {
      alert("Please log in to remove items from your cart.");
    }

    if (combosLoading) return <div>Loading...</div>;
    if (combosError) return <div>Error loading combos.</div>;
    if (!combosData) {
      return <div>No combos found for this night.</div>;
    }
  };

  const now = new Date().getTime();
  const countdownTime = new Date('2024-11-30T11:59:59').getTime();
  const difference = countdownTime-now

  return (
    <div className="combos">
      {addData?.AddtoCart ? (
        <Popup type="success" message="Added to cart" />
      ) : (
        ""
      )}
      {LoginPopUp ? (
        <Popup
          type="normal"
          message="Please Login in to add items to your cart"
        />
      ) : (
        ""
      )}
      <div className="z-20 absolute top-0 left-0 right-0 flex justify-center mt-4">
        <Navbar />
      </div>
      {device == "mobile" && difference>0  && <Banner/>}
      <div className={`combo ${device}`}>
        <div>
          {combo?.Image && (
            <img
              src={device == "mobile" && combo.mobileImage ? combo.mobileImage:combo.Image}
              alt={combo.Name}
              className={`combo-image ${device}`}
            />
          )}
        </div>
        <div className={`combo-details ${device}`}>
          <div className="">
            <h2 className={`combo-name ${device}`}>
              {combo?.Name.toUpperCase()}
            </h2>
            <p className={`combo-description ${device}`}>
              {combo?.Description}
            </p>
            <div className={`popup-row ${device}`}>
              <ImagePopup imageSrc={Oatlayout} linkText={"OAT Layout"} />
              {(combo?.type == "common" || combo?.type == "merch") && (
                <ImagePopup imageSrc={SizeChart} linkText={"Size Chart"} />
              )}
            </div>
            <div></div>
          </div>

          <div className={`hidden-part ${device}`}>
            <div className="price-and-quantity">
              <div className={`field-name ${device}`}>
                <div className="highlight w-24">Price</div>
                <div>:</div>
                <div className="w-24 flex justify-start">₹{combo?.Price} </div>
              </div>

              <div className={`field-name ${device}`}>
                <span className="highlight w-24">Quantity </span>
                <div>:</div>
                <div className="flex justify-start w-24">
                  <div className="flex  justify-start items-center">
                    <button
                      onClick={decreaseQuantity}
                      className="text-white quantity-button"
                    >
                      -
                    </button>
                    <div className="w-8 flex justify-center"> {quantity}</div>
                    <button
                      onClick={increaseQuantity}
                      className="text-white quantity-button"
                    >
                      +
                    </button>
                  </div>
                </div>
              </div>
              {(combo?.type == "common" || combo?.type == "merch") && (
                <div>
                  <div className={`field-name ${device}`}>
                    <div className="highlight w-24">Size</div>
                    <div>:</div>
                    <div className="w-24">
                      <select
                        id="combo-positions"
                        className={`combo-option-key ${device}`}
                        onChange={handlePositionChange}
                      >
                        <option
                          value=""
                          disabled
                          selected
                          className={`combo-option-key ${device}`}
                        >
                          Select
                        </option>
                        {sizes.map((size) => (
                          <option value={size} className="combo-option-value">
                            {size}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              )}
              {combo?.type == "merch" && (
                <div className={`field-name ${device}`}>
                  <div className="highlight w-24">Customization</div>
                  <div>:</div>
                  <div className="w-24 flex justify-start bg-black">
                    <input
                      className="w-24 combo-input"
                      type="text"
                      placeholder="Name"
                      onChange={handleCustomNameInput}
                    />
                  </div>
                </div>
              )}
              <div className={`field-name ${device}`}>
                <div className="highlight w-24">Total</div>
                <div>:</div>
                <div className="w-24 flex justify-start">
                  <div className="w-24 flex justify-start">
                    ₹
                    {(combo?.Price || 0) *
                      (quantity || 0)}
                  </div>{" "}
                </div>
              </div>
              {quantity>=5 && (<div className={`field-name ${device}`}>
                <div className="highlight w-24">Disc. Total</div>
                <div>:</div>
                <div className="w-24 flex justify-start">
                  <div className="w-24 flex justify-start">
                    ₹{((combo?.Price || 0) *(quantity || 0) *(0.95)).toFixed(2)}
                  </div>{" "}
                </div>
              </div>)}
            </div>

            <div className="flex buttons justify-between gap-2">
              <button
                className={`back-to-home-btn ${device}`}
                onClick={() => {
                  navigate("/");
                }}
              >
                Back to Home
              </button>
              <button
                className={`add-to-cart-btn ${device}`}
                onClick={handleAddToCart}
              >
                Add to Cart
              </button>
            </div>
            {/* <div>
                {Error}
              </div> */}
            <div className="text-red-700 justify-center w-full text-center">
              {sizeError!="" ? sizeError:""}
            </div>
            <div className="text-red-700 justify-center w-full text-center">
              {customNameError!=""? customNameError:""}
            </div>
          </div>
        
        </div>

        


      </div>
      {device !== "mobile" && difference>0 && <Banner/>}
      <div className={`${device=='mobile'? "flex flex-col":"flex"}`} >
        
      <div className={`otherCombos ${device}`}>
      <h3>Check out other Combos</h3>
      <div className={`combo-container ${device}`}>
        {OtherCombos.map((combo) => (
          <div key={combo.id} className="otherCombo">
            <img
              src={device == "mobile" && combo.mobileImage ? combo.mobileImage : combo.Image}
              alt={combo.Name}
              className="otherComboImg"
              onClick={()=>{navigate(`/combos/${combo.id}`);}}
            />
          </div>
        ))}
      </div>
    </div>
    <div className="flex justify-center align-center mt-3 w-full">
        {difference>0 && <div className={`timer ${device}`}>
          <Timer targetDate="2024-11-30T11:59:59"/>
        </div>}

    </div>
      </div>
    </div>
  );
};

export default Combos;

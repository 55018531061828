import React, { useState, useEffect } from "react";
import axiosInstance from "../../graphql/axiosConfig";
import AddComboPopup from "./addCombo";
import { useNavigate } from "react-router-dom";
import EditComboPopup from "./editCombo";

// Define types for Combo and Order

interface Items {
  proshows_list: string[];
  position_list: string[];
  merch_list: string[];
}
interface Combo {
  id: string;
  Name: string;
  Description: string;
  Image?: string;
  Price: number;
  active: string;
  soldTicketsCount?: number;
  available:number;
  open_to:string;
  type: string;
  items: Items; // Optional, to be calculated
}

interface Order {
  Name: string;
  Price: number;
  Quantity: number;
  Size?: string;
  customization?: string;
}

const CombosAdminPage: React.FC = () => {
  const [combos, setCombos] = useState<Combo[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [isAddPopupVisible, setIsAddPopupVisible] = useState<boolean>(false);
  const [isEditPopupVisible, setIsEditPopupVisible] = useState<boolean>(false);
  const [selectedCombo, setSelectedCombo] = useState<Combo | null>(null); 
  const [newCombo, setNewCombo] = useState<Partial<Combo>>({
    Name: "",
    Price: 0,
    Description: "",
    Image: "",
  });

  const handleAddCombo = () => {
    // setCombos((prevCombos) => [...prevCombos, combo
    // alert("combo added, pls reload")
  };
  const fetchCombos = async () => {
    setLoading(true);
    const query = `
      query GetAllCombos {
        getAllCombos {
          id
          Name
          Description
          Image
          mobileImage
          available
          Price
          open_to
          
          active
          type
        }
      }`;
    
      const response = await axiosInstance.post("", { query });
      const fetchedCombos: Combo[] = response.data.data.getAllCombos;

   
      const soldTicketsCounts = await Promise.all(
        fetchedCombos.map(async (proShow) => {
          const ordersQuery = `
            query GetOrdersByItem($itemId: String!, $itemType: String!) {
              getOrdersByItem(ItemID: $itemId, ItemType: $itemType) {
                Name
                Price
                Quantity
                Size
                customization
              }
            }`;
      
          const orderResponse = await axiosInstance.post(
            "",
            {
              query: ordersQuery,
              variables: { itemId: proShow.id, itemType: "Combo" },
            }
          );
      
          const totalQuantity = orderResponse.data.data.getOrdersByItem.reduce(
            (sum:any, order:any) => sum + order.Quantity,
            0
          );
      
          return {
            id: proShow.id,
            soldTicketsCount: totalQuantity,
          };
        })
      );
      
    
      const updatedCombos = fetchedCombos.map((combo) => {
        const orderCount = soldTicketsCounts.find(
          (count) => count.id === combo.id
        );
        return {
          ...combo,
          soldTicketsCount: orderCount?.soldTicketsCount || 0,
        };
      });
    
      setCombos(updatedCombos);
    };
   const toggleActivation = async (id: string, isActive: boolean) => {
    const confirmDeactivate = window.confirm("Are you sure you want to deactivate/activate this ProShow?");
    if (!confirmDeactivate) return;


    const mutation = isActive
      ? `mutation DeactivateCombo($deactivateComboId: String!) {
           deactivateCombo(id: $deactivateComboId)
         }`
      : `mutation ActivateCombo($activateComboId: String!) {
           activateCombo(id: $activateComboId)
         }`;

    try {
      await axiosInstance.post("", {
        query: mutation,
        variables: isActive
          ? { deactivateComboId: id }
          : { activateComboId: id },
      });
      fetchCombos(); // Refresh after toggle
    } catch (error: any) {
      alert(`Error toggling combo activation: ${error.message}`);
    }
  };

  function getEmailType(email:string) {
    if (email.endsWith('@smail.iitm.ac.in')) {
        return 'smail';
    } else if (email.endsWith('@alumni.iitm.ac.in')) {
        return 'alumni';
    } else {
        return 'public';
    }
}
  const downloadCSV = async (itemId: string) => {
    const query = `
      query GetOrdersCsvByItem($itemId: String!, $itemType: String!) {
        getOrdersCsvByItem(ItemID: $itemId, ItemType: $itemType) {
          order {
            Name
            Price
            Quantity
          }
          user {
            username
            email_id
            contact_no
          }
           payment{
          OrderDate
          }
        }
      }
    `;
  
    const response = await axiosInstance.post("", {
      query,
      variables: { itemId, itemType: "Combo" },
    });
  
    const orders = response.data.data.getOrdersCsvByItem;
  
    // If no orders are available, return early.
    if (!orders || orders.length === 0) {
      alert("No orders found for this item.");
      return;
    }
  
    // Prepare CSV rows with the user details
    const csvRows = [
      ["Order Name", "User Name","Type", "Email", "Contact No.", "Price (₹)", "Quantity", "Amount","Discount","Date"],
      ...orders.map((order: any) => [
        order.order.Name,
        order.user.username,
getEmailType(order.user.email_id),
        order.user.email_id,
        order.user.contact_no,
        order.order.Price,
        order.order.Quantity,
        order.order.Price * order.order.Quantity*(order.order.Quantity >= 5 && order.order.Quantity ? 0.95 : 1),
order.order.Price*order.Quantity*(order.order.Quantity>=5 && order.order.Quantity?0.5:0),
        order.payment.OrderDate
      ]),
    ];
  
    // Convert the rows to a CSV string
    const csvString = csvRows.map((row) => row.join(",")).join("\n");
  
    // Create a Blob from the CSV string
    const blob = new Blob([csvString], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
  
    // Generate the filename as `order.<Name>_<date>.csv`
    const today = new Date();
    const formattedDate = `${today.getFullYear()}-${(today.getMonth() + 1)
      .toString()
      .padStart(2, "0")}-${today
      .getDate()
      .toString()
      .padStart(2, "0")}`;
    const orderName = orders[0].order.Name;
    const filename = `${orderName}_${formattedDate}.csv`;
  
    // Create a download link and trigger it
    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", filename);
    link.click();
  };
  
  
 const navigate = useNavigate()

  useEffect(() => {
    fetchCombos();
  }, []);
  
  async function LogOutHandler(e: React.FormEvent) {
    
      e.preventDefault();
      try {
        const res = await axiosInstance.post("", {
          query: `mutation Mutation { Logout }`
        });

        if (res.data.errors) {
          // Handle errors if necessary
        }
        navigate('/auth');
      } catch (e: any) {
      }
    } 
    
  

  return (
    <div className="min-h-screen p-8 bg-gray-800 text-black flex flex-col">
      <div className="flex justify-between items-center mb-4">
        <button
          onClick={() => setIsAddPopupVisible(true)}
          className="bg-red-500 text-white px-4 py-2 rounded"
        >
          Add Combo
        </button>
        <h1 className="text-3xl text-center">Combos Admin Panel</h1>
        <nav className="space-x-4">
  <button
    className="bg-blue-100 text-gray-800 px-4 py-2 rounded"
    onClick={() => navigate("/admin")}
  >
    ProShows
  </button>
  <button
    className="bg-blue-100 text-gray-800 px-4 py-2 rounded"
    onClick={() => navigate("/admin/combo")}
  >
    Combos
  </button>
  <button
    className="bg-blue-100 text-gray-800 px-4 py-2 rounded"
    onClick={() => navigate("/")}
  >
    Portal
  </button>
  <button
    className="bg-red-400 text-gray-800 px-4 py-2 rounded"
    onClick={LogOutHandler}
  >
    Logout
  </button>
</nav>
      </div>

      <table className="min-w-full bg-gradient-to-r from-red-300 to-red-400 rounded-lg shadow-lg">
        <thead>
          <tr className="bg-gradient-to-r from-red-400 to-red-500 text-black">
            <th className="py-3 px-6 text-left w-1/6">Name</th>
            <th className="py-3 px-6 text-left w-1/6">Type</th>
            <th className="py-3 px-6 text-left w-1/6">Open for</th>

            <th className="py-3 px-6 text-left w-1/6">Price (₹)</th>
            <th className="py-3 px-6 text-left w-1/6">Description</th>
            <th className="py-3 px-6 text-left w-1/6">Image</th>
            <th className="py-3 px-6 text-left w-1/6">Available</th>

            <th className="py-3 px-6 text-left w-1/6">Sold</th>
            <th className="py-3 px-6 text-left w-1/6">Edit</th>

            <th className="py-3 px-6 text-left w-1/6">CSV</th>

            <th className="py-3 px-6 text-left w-1/6">Activity</th>
          </tr>
        </thead>
        <tbody>
          {combos.map((combo) => (
            <tr
              key={combo.id}
              className="border-b border-red-400 hover:bg-red-200 transition-colors duration-300"
            >
              <td className="py-4 px-6">{combo.Name}</td>
              <td className="py-4 px-6">{combo.type}</td>
              <td className="py-4 px-6">{combo.open_to}</td>

              <td className="py-4 px-6">{combo.Price}</td>
              <td className="py-4 px-6">{combo.Description}</td>
              <td className="py-4 px-6">
                <img src={combo.Image} alt={combo.Name} className="w-16" />
              </td>
              <td className="py-4 px-6">{combo.available}</td>

              <td className="py-4 px-6">{combo.soldTicketsCount}</td>
              <td className="py-4 px-6">
                <button
                  onClick={() => {
                    setSelectedCombo(combo); // Set the selected combo for editing
                    setIsEditPopupVisible(true); // Show the edit popup
                  }}
                  className="bg-yellow-600 text-white px-4 py-2 rounded transition-transform transform hover:scale-105"
                >
                  Edit
                </button>
              </td>
              <td className="py-4 px-6">
                <button
                  onClick={() => downloadCSV(combo.id)}
                  className="bg-red-500 text-white px-4 py-2 rounded transition-transform transform hover:scale-105"
                >
                  CSV
                </button>
              </td>

              <td className="py-4 px-6">
                {combo.active === null ||
               ( combo.active.toLowerCase() !== "false" &&
                combo.active.toLowerCase() !== "inactive") ? (
                  <>
                    <button
                      onClick={() => toggleActivation(combo.id,true)}
                      className="bg-red-600 text-white px-4 py-2 rounded transition-transform transform hover:scale-105"
                      style={{ width: '120px' }}
                    >
                      Deactivate
                    </button>
                  </>
                ) : (
                  <>
                    <button
                      onClick={() => toggleActivation(combo.id,false)}
                      className="bg-green-600 text-white px-4 py-2 rounded transition-transform transform hover:scale-105"
                      style={{ width: '120px' }}
                    >
                      Activate
                    </button>
                  </>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <AddComboPopup
        isVisible={isAddPopupVisible}
        onClose={() => setIsAddPopupVisible(false)}
        onAddCombo={handleAddCombo}
      />
        <EditComboPopup
        isVisible={isEditPopupVisible}
        onClose={() => setIsEditPopupVisible(false)}
        onComboEdit={fetchCombos} 
        combo={selectedCombo!} // Pass the selected combo
      />
    </div>
  );
};

export default CombosAdminPage;

import React from "react";
import axios from "axios";

var timeout = 10000
let api_url = 'https://api.sales.saarang.org/graphql'

if(process.env.APP === 'testing'){
   let  api_url = 'http://localhost:8000/graphql'

    timeout = 10000
}


const axiosInstance = axios.create({
    baseURL: api_url,
    timeout: timeout,
    withCredentials: true,
});



export default axiosInstance ; 
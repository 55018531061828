import { ApolloClient, InMemoryCache } from "@apollo/client";
let api_url = 'https://api.sales.saarang.org/graphql'


if(process.env.APP === 'testing'){
  let api_url = 'http://localhost:8000/graphql'
     
 }
const client = new ApolloClient({
  uri:api_url ,
  cache: new InMemoryCache(),
  credentials: "include",
});

export default client;
